:root {
  --rpvue-ActionableList-actions-gap:   var(--rpvue-spacer-2);
}

.rpvue-ActionableList-actions {
  display: flex;
  align-items: center;

  gap: var(--rpvue-ActionableList-actions-gap);
}
