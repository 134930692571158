@import "ActionableList/ActionableList";
@import "Avatar/Avatar";
@import "Badge/Badge";
@import "DynamicList";
@import "NumberRange/NumberRange";
@import "QuadroContainer/QuadroContainer";
@import "SearchableList/SearchableList";

.rp-vue-app-container {
  flex: 1 1 auto;
  min-width: 0;
}
