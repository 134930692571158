@mixin interactive-card() {
  border: var(--rpvue-borderWidth) solid var(--rpvue-colorpalettePurple-200);
  border-radius: var(--rpvue-borderRadiusLg);
  background: var(--rpvue-colorNeutralSurface);

  &.with-hover-actions:hover,
  &[data-interactive-element]:hover {
    border-color: var(--rpvue-colorpalettePurple-300);

    &.button-like {
      background: var(--rpvue-colorpalettePurple-100);
    }
  }

  &[data-interactive-element] {
    cursor: pointer;

    &.active {
      // TODO should be "Blue 300" from updated color palette
      border-color: #9fbbfc;

      &.button-like {
        background: var(--rpvue-colorpalettePurple-100);
      }
    }
  }

  &.button-like.disabled {
    // TODO should be "Gray 500" from updated color palette
    color: #747b7f;
    border-color: var(--rpvue-colorpalettePurple-200);
    background: var(--rpvue-colorpalettePurple-100);
  }
}

@mixin hover-actions() {
  .hover-actions {
    display: none;

    position: absolute;
    top: var(--rpvue-spacer-2);
    right: var(--rpvue-spacer-2);
    z-index: 2;

    padding: var(--rpvue-spacer-2);
    gap: var(--rpvue-spacer-4);

    border: var(--rpvue-borderWidth) solid var(--rpvue-colorpalettePurple-300);
    border-radius: var(--rpvue-borderRadiusSm);
    background: var(--rpvue-colorNeutralSurface);
  }

  &:hover {
    .hover-actions {
      display: flex;
    }
  }
}

@mixin font-settings($font-map) {
  @each $property, $value in $font-map {
    #{$property}: $value;
  }
}

@mixin hide-scrollbar() {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin hide-caret() {
  &::before {
    display: none !important;
  }
  &::after {
    display: none !important;
  }
}

@mixin element-underlined() {
  & {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;
  }
}
