:root {
  --rpvue-DynamicList-marginBottom:             var(--rpvue-spacer-6);
  --rpvue-DynamicList-border:                   var(--rpvue-borderWidth) solid var(--rpvue-colorBorder);
  --rpvue-DynamicList-borderRadius:             var(--rpvue-borderRadiusLg);

  --rpvue-DynamicList-header-background:        var(--rpvue-colorpalettePurple-100);
  --rpvue-DynamicList-header-borderBottom:      var(--rpvue-borderWidth) solid var(--rpvue-colorBorder-alt);

  --rpvue-DynamicList-content-background:       var(--rpvue-colorNeutralSurface);
}

.rpvue-DynamicList {
  display: flex;
  flex-direction: column;

  overflow: hidden;

  margin-bottom: var(--rpvue-DynamicList-marginBottom);

  border: var(--rpvue-DynamicList-border);
  border-radius: var(--rpvue-DynamicList-borderRadius);
}

.rpvue-DynamicList-header {
  background: var(--rpvue-DynamicList-header-background);
  border-bottom: var(--rpvue-DynamicList-header-borderBottom);
}

.rpvue-DynamicList-content {
  background: var(--rpvue-DynamicList-content-background);
}
