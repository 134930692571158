body {
  background: $neutral-bg;
}

main {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

body.has-header {
  main {
    padding-top: $header-height;
  }
}

@include media-breakpoint-up(md) {
  body.has-vue-sidebar {
    main {
      padding-left: $vue-sidebar-width;
    }
  }
}

.main-table-container,
.detail-page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-table-container {
  padding: $content-padding-x $content-padding-x $content-padding-y;
}

.detail-page-container {
  padding: $content-padding-y $content-padding-x;
}

.detail-page-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$content-padding-x * .5;
  margin-left: -$content-padding-x * .5;

  > .col,
  > [class*="col-"] {
    padding-right: $content-padding-x * .5;
    padding-left: $content-padding-x * .5;
  }
}
