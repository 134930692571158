:root {
  --rpvue-SearchableList-search-padding:  var(--rpvue-spacer-3) 0;
}

.rpvue-SearchableList-search {
  padding: var(--rpvue-SearchableList-search-padding);

  input {
    border-radius: var(--rpvue-borderRadiusSm);
  }
}
