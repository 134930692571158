:root {
  --rpvue-DynamicListHeader-padding:  var(--rpvue-spacer-3) var(--rpvue-spacer-6);
  --rpvue-DynamicListHeader-gap:      var(--rpvue-spacer-7);
}

.rpvue-DynamicListHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--rpvue-DynamicListHeader-padding);
  gap: var(--rpvue-DynamicListHeader-gap);
}

.rpvue-DynamicListHeader-content {
  &.is-full-width {
    flex: 1;
  }
}
