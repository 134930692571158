:root {
  --rpvue-DynamicListContent-padding:     var(--rpvue-spacer-7);
}

.rpvue-DynamicListContent {
  display: flex;
  flex-direction: column;

  padding: var(--rpvue-DynamicListContent-padding);
}

.rpvue-DynamicListContent-message {
  display: flex;
  align-items: center;
  justify-content: center;
}
