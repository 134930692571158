// ############################################################################
// Constants
// ############################################################################

$rp-icon-btn-border-width:              $border-width;
$rp-icon-btn-disabled-opacity:          .5;
$rp-icon-btn-transition:                color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

$rp-icon-btn-standard: (
  color:                                $neutral-on-bg,
  background:                           transparent,
  border:                               transparent,
  hover-color:                          $neutral-on-bg,
  hover-background:                     $palette-purple-100,
  hover-border:                         $palette-purple-100,
  active-color:                         $neutral-on-bg,
  active-background:                    $palette-purple-200,
  active-border:                        $palette-purple-200
);

$rp-icon-btn-outline: (
  color:                                $neutral-on-bg,
  background:                           transparent,
  border:                               $palette-purple-200,
  hover-color:                          $neutral-on-bg,
  hover-background:                     $palette-purple-100,
  hover-border:                         $palette-purple-200,
  active-color:                         $neutral-on-bg,
  active-background:                    $palette-purple-200,
  active-border:                        $palette-purple-200
);

$rp-icon-btn-filled: (
  color:                                $neutral-on-bg,
  background:                           $palette-purple-200,
  border:                               $palette-purple-200,
  hover-color:                          $neutral-on-bg,
  hover-background:                     $palette-purple-300,
  hover-border:                         $palette-purple-300,
  active-color:                         $neutral-surface,
  active-background:                    $palette-purple-600,
  active-border:                        $palette-purple-600
);

$rp-icon-btn-lg: (
  size:                                 2.25rem,
  icon-size:                            $icon-size-lg,
  border-radius:                        .25rem
);

$rp-icon-btn-md: (
  size:                                 2rem,
  icon-size:                            $icon-size-md,
  border-radius:                        .25rem
);

$rp-icon-btn-sm: (
  size:                                 1.5rem,
  icon-size:                            $icon-size-md,
  border-radius:                        .125rem
);

$rp-icon-btn-xs: (
  size:                                 1.125rem,
  icon-size:                            $icon-size-xs,
  border-radius:                        .125rem
);

// ############################################################################
// Mixins
// ############################################################################

@mixin icon-button-style($style-map) {
  $color: map-get($style-map, "color");
  $background: map-get($style-map, "background");
  $border: map-get($style-map, "border");
  $hover-color: map-get($style-map, "hover-color");
  $hover-background: map-get($style-map, "hover-background");
  $hover-border: map-get($style-map, "hover-border");
  $active-color: map-get($style-map, "active-color");
  $active-background: map-get($style-map, "active-background");
  $active-border: map-get($style-map, "active-border");

  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover {
    color: $hover-color;
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: $background;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;
  }
}

@mixin icon-button-size($size-map) {
  $size: map-get($size-map, "size");
  $icon-size: map-get($size-map, "icon-size");
  $border-radius: map-get($size-map, "border-radius");

  width: $size;
  height: $size;

  border-radius: $border-radius;

  .rp-icon-btn-icon,
  .rp-icon-btn-loader {
    font-size: $icon-size;
  }
}

// ############################################################################
// Styles
// ############################################################################

@keyframes rp-icon-btn-loader {
  to {
    transform: rotate(360deg);
  }
}

.rp-icon-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;

  user-select: none;
  text-decoration: none !important;

  background: transparent;
  border: $rp-icon-btn-border-width solid transparent;

  @include icon-button-style($rp-icon-btn-standard);
  @include icon-button-size($rp-icon-btn-md);

  @include transition($rp-icon-btn-transition);

  &:hover {
    text-decoration: none !important;
  }

  &:focus,
  &.focus {
    outline: none;
  }

  &:disabled,
  &.disabled {
    opacity: $rp-icon-btn-disabled-opacity;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .rp-icon-btn-loader {
    animation: .75s linear infinite rp-icon-btn-loader;
  }

  &.loading {
    .rp-icon-btn-icon {
      display: none;
    }
  }

  &:not(.loading) {
    .rp-icon-btn-loader {
      display: none;
    }
  }

  .rp-icon-btn-disabled-tooltip {
    display: none;

    position: absolute;
    top: -$rp-icon-btn-border-width;
    left: -$rp-icon-btn-border-width;
    right: -$rp-icon-btn-border-width;
    bottom: -$rp-icon-btn-border-width;
    z-index: 2;
  }

  &:disabled:not(.loading),
  &.disabled:not(.loading) {
    .rp-icon-btn-disabled-tooltip {
      display: block;
    }
  }
}

a.rp-icon-btn.disabled {
  pointer-events: none;
}

.rp-icon-btn-outline {
  @include icon-button-style($rp-icon-btn-outline);
}

.rp-icon-btn-filled {
  @include icon-button-style($rp-icon-btn-filled);
}

.rp-icon-btn-lg {
  @include icon-button-size($rp-icon-btn-lg);
}

.rp-icon-btn-sm {
  @include icon-button-size($rp-icon-btn-sm);
}

.rp-icon-btn-xs {
  @include icon-button-size($rp-icon-btn-xs);
}
