.rpvue-DynamicListPartials {
  display: flex;
  flex-direction: column;
}

.rpvue-DynamicListPartials-partial {
  // style fixes for possible content

  .rp-interactive-list-item {
    margin-bottom: $rp-list-items-spacing;
  }
}
